// ** Icon imports
import HomeOutline from 'mdi-material-ui/HomeOutline';
import ShieldOutline from 'mdi-material-ui/ShieldOutline';

// ** Type import
import { HorizontalNavItemsType } from 'src/@core/layouts/types';
import ShieldCrown from 'mdi-material-ui/ShieldCrown';

const navigation = (): HorizontalNavItemsType => [
  {
    title: 'Home',
    icon: HomeOutline,
    path: '/home'
  },
  {
    title: 'Retailer Admin',
    icon: ShieldCrown,
    children: [
      {
        title: 'Onboarding link',
        path: '/retailer-admin/generate-retailer-link'
      },
      {
        title: 'Import settings',
        path: '/retailer-admin/import-settings'
      },
      {
        title: 'Advanced Import settings',
        path: '/retailer-admin/advanced-import-settings'
      }
    ]
  },
  {
    title: 'Access Control',
    icon: ShieldOutline,
    path: '/acl',
    action: 'read',
    subject: 'acl-page'
  }
];

export default navigation;
