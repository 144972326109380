import { ReactNode, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import type { ACLObj, AppAbility } from 'src/configs/acl';
import { AbilityContext } from 'src/layouts/components/acl/Can';
import { buildAbilityFor } from 'src/configs/acl';
import NotAuthorized from 'src/pages/401';
import BlankLayout from 'src/@core/layouts/BlankLayout';
import { useSession } from 'next-auth/react';
import { IAuthUser } from '../../../types/apps/userTypes';

interface AclGuardProps {
  children: ReactNode;
  guestGuard: boolean;
  aclAbilities: ACLObj;
}

const AclGuard = (props: AclGuardProps) => {
  const { aclAbilities, children, guestGuard } = props;

  const [user, setUser] = useState<IAuthUser | null>();

  const [ability, setAbility] = useState<AppAbility | undefined>(undefined);
  const session = useSession();
  const router = useRouter();

  useEffect(() => {
    if (session.status === 'authenticated') {
      setUser(session.data.user);
    }
  }, [session.status]);

  // If guestGuard is true and user is not logged in or its an error page, render the page without checking access
  if (guestGuard || router.route === '/404' || router.route === '/500' || router.route === '/') {
    return <>{children}</>;
  }

  // User is logged in, build ability for the user based on his role
  if (user?.permissions && !ability) {
    //
    // add user acl instead of role name, i do not have role names as they comes from database
    //
    // setAbility(buildAbilityFor(auth.user.role, aclAbilities.subject));
    setAbility(buildAbilityFor(user.permissions));
  }

  // Check the access of current user and render pages
  if (ability && ability.can(aclAbilities.action, aclAbilities.subject)) {
    return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
  }

  // Render Not Authorized component if the current user has limited access
  return (
    <BlankLayout>
      <NotAuthorized />
    </BlankLayout>
  );
};

export default AclGuard;
