import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const FallbackSpinner = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      {/*eslint-disable-next-line jsx-a11y/alt-text*/}
      <img src='/spinner-mbb-logo.png' width={32} height={32} />
      <p style={{ textAlign: 'center' }}>
        MYBESTBRANDS
        <br />
        Backoffice International
      </p>
      <CircularProgress disableShrink sx={{ mt: 6 }} />
    </Box>
  );
};

export default FallbackSpinner;
