import axios from 'axios';
import type { AxiosError } from 'axios';
import { SOMETHING_WENT_WRONG_MESSAGE } from 'src/lib/constants';
import { API_ROUTES, HTTPStatus } from './constants';
import { ILogData } from '../../types/apps/logsTypes';

axios.defaults.baseURL = process.env.NEXT_PUBLIC_HOST;

interface IResponse<T> {
  data: T | null;
  error: string | null;
}

interface IResponseWithMessage<T> {
  data: T | null;
  error: string | null;
  status: number;
}

interface IRequest {
  path: string;
  headers?: Record<string, string>;
}

interface IGet extends IRequest {
  queryParams?: Record<string, unknown>;
}

interface IPost extends IRequest {
  data?: Record<string, unknown>;
  changeLogData?: ILogData;
  saveChangeLog?: boolean;
}

interface IPut extends IRequest {
  data: Record<string, unknown>;
  changeLogData?: ILogData;
  saveChangeLog?: boolean;
}

interface IDelete extends IRequest {
  data: Record<string, unknown>;
  changeLogData?: ILogData;
  saveChangeLog?: boolean;
}

export const getData = async <ReturnType>({
  path,
  queryParams = {},
  headers = {}
}: IGet): Promise<IResponse<ReturnType>> => {
  try {
    const res = await axios.get(path, { params: queryParams, headers });

    return { data: res.data, error: null };
  } catch (error) {
    const err = error as AxiosError;
    const msg = err?.response?.statusText ?? SOMETHING_WENT_WRONG_MESSAGE;

    return { data: null, error: msg };
  }
};

// DEPRECIATED, USE axiosPostData
export const postData = async <ReturnType>({
  path,
  data = {},
  headers = {},
  saveChangeLog = false,
  changeLogData = {
    entity_type: '',
    entity_id: 0,
    changed_data: null,
    country_id: ''
  }
}: IPost): Promise<IResponse<ReturnType>> => {
  try {
    const res = await axios.post(path, data, { headers });

    // for saving entity changelog
    if (res && res.status === HTTPStatus.OK && saveChangeLog)
      await axios.put(API_ROUTES.LOGS.ENTITYCHANGELOG, changeLogData, { headers });

    return { data: res.data, error: null };
  } catch (error) {
    const err = error as AxiosError;
    const msg = err?.response?.statusText ?? SOMETHING_WENT_WRONG_MESSAGE;

    return { data: null, error: msg };
  }
};

/* ChangelogData: {entity_type: 'retailer' or 'brand', entity_id: RetailerId or BrandId, changedData: any object} */
export const axiosPostData = async <ReturnType>({
  path,
  data = {},
  headers = {},
  saveChangeLog = false,
  changeLogData = {
    entity_type: '',
    entity_id: 0,
    changed_data: null,
    country_id: ''
  }
}: IPost): Promise<IResponseWithMessage<ReturnType>> => {
  try {
    const res = await axios.post(path, data, { headers });

    // for saving entity changelog
    if (res && res.status === HTTPStatus.OK && saveChangeLog)
      await axios.put(API_ROUTES.LOGS.ENTITYCHANGELOG, changeLogData, { headers });

    return { data: res?.data, error: null, status: res?.status };
  } catch (error) {
    const err = error as AxiosError;
    const data = err?.response?.data as string;

    const msg = data && data.length > 0 ? data : SOMETHING_WENT_WRONG_MESSAGE;

    return {
      data: null,
      error: msg,
      status: err?.response?.status || 0
    };
  }
};

export const putData = async <ReturnType>({
  path,
  data = {},
  headers = {},
  saveChangeLog = false,
  changeLogData = {
    entity_type: '',
    entity_id: 0,
    changed_data: null,
    country_id: ''
  }
}: IPut): Promise<IResponse<ReturnType>> => {
  try {
    const res = await axios.put(path, data, { headers });

    // for saving entity changelog
    if (res && res.status === HTTPStatus.OK && saveChangeLog) {
      await axios.put(API_ROUTES.LOGS.ENTITYCHANGELOG, changeLogData, { headers });
    }

    return { data: res.data, error: null };
  } catch (error) {
    const err = error as AxiosError;
    const msg = err?.response?.statusText ?? SOMETHING_WENT_WRONG_MESSAGE;

    return { data: null, error: msg };
  }
};

export const deleteData = async <ReturnType>({
  path,
  data = {},
  headers = {},
  saveChangeLog = false,
  changeLogData = {
    entity_type: '',
    entity_id: 0,
    changed_data: null,
    country_id: ''
  }
}: IDelete): Promise<IResponse<ReturnType>> => {
  try {
    const res = await axios.delete(path, { data, headers });

    // for saving entity changelog
    if (res && res.status === HTTPStatus.OK && saveChangeLog)
      await axios.put(API_ROUTES.LOGS.ENTITYCHANGELOG, changeLogData, { headers });

    return { data: res.data, error: null };
  } catch (error) {
    const err = error as AxiosError;
    const msg = err?.response?.statusText ?? SOMETHING_WENT_WRONG_MESSAGE;

    return { data: null, error: msg };
  }
};
