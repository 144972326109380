// const Sentry = require('@sentry/nextjs');

// Sentry.init({
//   dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
//   enabled: true,
//   environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
//   sampleRate: 1.0
// });

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

// autoSessionTracking: false,
//   enabled: process.env.NODE_ENV === 'production',
//   environment: process.env.NEXT_PUBLIC_environment,
//   ignoreErrors,
//   sampleRate: 1.0,
//   dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

Sentry.init({
  dsn: process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  enabled: process.env.NODE_ENV === 'production',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
