import type { NextApiRequest, NextApiResponse, NextApiHandler } from 'next/types';
import { HTTPStatus } from 'src/lib/request';
import type { IACLPermissions } from 'src/types/apps/accessControlListTypes';
import { getToken } from 'next-auth/jwt';

enum AclSubjects {
  UsersManagement = 'User accounts management',

  // Note: where we assign role permissions per acl subject
  ACLManagement = 'Access control list management',

  // Note: this is where a key account manager can create a link to the onboarding form for a shop
  OnboardingLinkManagement = 'Onboarding Link Management',

  // Note: this is role for managing retailer's import settings (feeds)
  ImportSettingsManagement = 'Import Settings Management',

  //Note: this is for creating link for registration additional partnerweb account
  InvitationLinkManagement = 'Invitation Link Management',

  //Note: this is for accessing Retailer Admin
  RetailerManagement = 'Retailer Management',

  //Note: this is for external performance marketing, gives access to retailer budget overview
  ExternalPerfMarketing = 'External Performance Marketing',

  //Note: this is for coupon management, gives access to coupons
  CouponManagement = 'Coupon Management',

  //Note: this is for css settings, gives access to css settings
  CssSettingsManagement = 'Css Settings Management',

  //Note: this is for managing the automated cpc rules
  AutomatedCpcRules = 'Automated CPC Rules'
}

enum AclPermissions {
  read = 'read',
  write = 'write'
}

const PERMISSIONS_PER_ACL_SUBJECT = 2; // read, write

const AclGuard =
  (aclPermission: AclPermissions, aclSubject: AclSubjects) =>
  async (req: NextApiRequest, res: NextApiResponse, next: NextApiHandler) => {
    const session = await getToken({ req });

    //@ts-ignore
    const acl = session.user?.permissions?.find((_: IACLPermissions) => _[aclSubject]);
    const permissions = acl?.[aclSubject];

    if (!permissions?.[aclPermission]) {
      res.statusMessage = "You don't have permission. Please contact support.";

      return res.status(HTTPStatus.Forbidden).end();
    }

    next(req, res);
  };

export { AclSubjects, AclPermissions, AclGuard, PERMISSIONS_PER_ACL_SUBJECT };
