// import { useContext } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from 'mdi-material-ui/Menu';
import { Settings } from 'src/@core/context/settingsContext';
import ModeToggler from 'src/@core/layouts/components/shared-components/ModeToggler';
import UserDropdown from 'src/layouts/components/UserDropdown';

// import ListItemText from '@mui/material/ListItemText';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import { AuthContext } from 'src/context/AuthContext';
// import FormControl from '@mui/material/FormControl';
// import MenuItem from '@mui/material/MenuItem';
// import InputLabel from '@mui/material/InputLabel';
// import { useRouter } from 'next/router';
// import type { IAuthUser } from 'src/types/apps/userTypes';
// import { SINGLE_COUNTRY_AVAILABLE } from 'src/lib/constants';

interface Props {
  hidden: boolean;
  settings: Settings;
  toggleNavVisibility: () => void;
  saveSettings: (values: Settings) => void;
}

const AppBarContent = (props: Props) => {
  // const auth = useContext(AuthContext);
  // const router = useRouter();
  const { hidden, settings, saveSettings, toggleNavVisibility } = props;

  // const renderCountryOptions = (countryCodes: string[]) =>
  //   countryCodes.map((countryCode: string) => (
  //     <MenuItem key={countryCode} value={countryCode}>
  //       <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
  //         {/* eslint-disable-next-line jsx-a11y/alt-text */}
  //         <img
  //           src={`/country/4x3/${countryCode}.svg`}
  //           width={30}
  //           height={20}
  //           style={{ paddingRight: 10 }}
  //         />
  //         <ListItemText primary={`${countryCode.toUpperCase()}`}></ListItemText>
  //       </div>
  //     </MenuItem>
  //   ));

  // // Checkmark docs: https://mui.com/material-ui/react-select/#checkmarks
  // const renderCountriesPicker = () => {
  //   const { countryCodes } = auth.user as IAuthUser;

  //   if (countryCodes.length === SINGLE_COUNTRY_AVAILABLE) return null;

  //   return (
  //     <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
  //       <InputLabel id='demo-controlled-open-select-label'></InputLabel>
  //       <Select
  //         value={router.locale}
  //         label=''
  //         onChange={({ target }: SelectChangeEvent<string>) => {
  //           auth.switchBackofficeCountry(target.value);
  //         }}
  //       >
  //         {renderCountryOptions(countryCodes)}
  //       </Select>
  //     </FormControl>
  //   );
  // };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      {hidden ? (
        <IconButton color='inherit' sx={{ ml: -2.75 }} onClick={toggleNavVisibility}>
          <MenuIcon />
        </IconButton>
      ) : null}

      <ModeToggler settings={settings} saveSettings={saveSettings} />
      {/* {renderCountriesPicker()} */}
      <UserDropdown settings={settings} />
    </Box>
  );
};

export default AppBarContent;
