import HomeOutline from 'mdi-material-ui/HomeOutline';
import ShieldCrown from 'mdi-material-ui/ShieldCrown';
import LockOutline from 'mdi-material-ui/LockOutline';
import { VerticalNavItemsType } from 'src/@core/layouts/types';
import AccountOutline from 'mdi-material-ui/AccountOutline';
import { AclPermissions, AclSubjects } from 'src/lib/acl';
import { ACL_DASHBOARD_SUBJECT } from 'src/configs/acl';
import ShieldMoonOutline from 'mdi-material-ui/ShieldMoonOutline';

const navigation = (): VerticalNavItemsType => [
  {
    title: 'Dashboard',
    icon: HomeOutline,
    path: '/dashboard',
    action: AclPermissions.read,
    subject: ACL_DASHBOARD_SUBJECT
  },
  {
    title: 'Retailers',
    icon: ShieldCrown,
    children: [
      {
        title: 'Onboarding link',
        path: '/retailer-admin/generate-retailer-link',
        action: AclPermissions.write,
        subject: AclSubjects.OnboardingLinkManagement
      },
      {
        title: 'Import Settings',
        path: '/retailer-admin/import-settings',
        action: AclPermissions.write,
        subject: AclSubjects.ImportSettingsManagement
      },
      {
        title: 'Advanced Import settings',
        path: '/retailer-admin/advanced-import-settings',
        action: AclPermissions.write,
        subject: AclSubjects.ImportSettingsManagement
      },
      {
        title: 'Retailer Product Count',
        path: '/retailer-admin/retailer-product-count',
        action: AclPermissions.write,
        subject: AclSubjects.RetailerManagement
      },
      {
        title: 'Additional Partnerweb Account',
        path: '/retailer-admin/additional-partnerweb-account',
        action: AclPermissions.write,
        subject: AclSubjects.InvitationLinkManagement
      },
      {
        title: 'Retailer Overview',
        path: '/retailer-admin/retailer-overview',
        action: AclPermissions.write,
        subject: AclSubjects.RetailerManagement
      },
      {
        title: 'Retailer Budget Overview',
        path: '/retailer-admin/budget-overview',
        action: AclPermissions.write,
        subject: AclSubjects.ExternalPerfMarketing
      },
      {
        title: 'Retailer Coupons',
        path: '/retailer-admin/retailer-coupons',
        action: AclPermissions.write,
        subject: AclSubjects.CouponManagement
      },
      {
        title: 'Scheduled CS Rules',
        path: '/retailer-admin/automated-cpc-rules',
        action: AclPermissions.write,
        subject: AclSubjects.AutomatedCpcRules
      }
    ]
  },
  {
    title: 'CSS Settings',
    icon: ShieldMoonOutline,
    children: [
      {
        title: 'Retailers Css Overview',
        path: '/css/retailer-css-overview',
        action: AclPermissions.write,
        subject: AclSubjects.RetailerManagement
      }
    ]
  },
  // {
  //   title: 'Access Control',
  //   icon: ShieldOutline,
  //   path: '/acl'
  // },
  {
    title: 'Users',
    icon: AccountOutline,
    action: AclPermissions.write,
    subject: AclSubjects.UsersManagement,
    children: [
      {
        title: 'List',
        path: '/apps/user/list',
        action: AclPermissions.write,
        subject: AclSubjects.UsersManagement
      }

      // {
      //   title: 'View',
      //   path: '/apps/user/view'
      // }
    ]
  },
  {
    title: 'ACL',
    icon: LockOutline,
    path: '/apps/role',
    action: AclPermissions.write,
    subject: AclSubjects.ACLManagement
  }
];

export default navigation;
