import { ReactNode, ReactElement, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';

interface GuestGuardProps {
  children: ReactNode;
  fallback: ReactElement | null;
}

const GuestGuard = (props: GuestGuardProps) => {
  const { children, fallback } = props;
  const session = useSession();
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (session.status === 'authenticated') {
      // router.replace('/');
      router.replace(`/${router.locale}/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.route]);

  // if (auth.loading || (!auth.loading && auth.user !== null)) {
  if (session.status === 'loading') {
    return fallback;
  }

  return <>{children}</>;
};

export default GuestGuard;
