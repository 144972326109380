import { ReactNode, ReactElement, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';

interface AuthGuardProps {
  children: ReactNode;
  fallback: ReactElement | null;
}

const AuthGuard = (props: AuthGuardProps) => {
  const { children, fallback } = props;
  const router = useRouter();
  const session = useSession();

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    // Note:
    // If user comes with organic link eg. https://i18n-bo.loc/dashboard/
    // NextJS will not redirect to country specific URL https://i18n-bo.loc/<COUNTRY CODE>/dashboard/
    // if comes to home route (https://i18n-bo.loc/) and if {NEXT_LOCALE} cookie set to a country code will
    // redirect.
    //
    // https://nextjs.org/docs/advanced-features/i18n-routing#leveraging-the-next_locale-cookie
    if (session.status === 'unauthenticated') {
      if (router.asPath !== '/') {
        // let returnUrl = router.asPath;
        // const locale = router.asPath.split('/').filter(_ => _)[0];
        // const countryCodes = Object.keys(COUNTRIES);
        // if (!countryCodes.includes(locale)) returnUrl = `/${router.locale}/${returnUrl}`;

        router.replace({
          pathname: `/${router.locale}/login`,
          query: { returnUrl: router.asPath } // { returnUrl }
        });
      } else {
        router.replace(`/${router.locale}/login`);
      }
    }
  }, [router, session]);

  if (session.status === 'loading') {
    return fallback;
  }

  return <>{children}</>;
};

export default AuthGuard;
