//
// CRUD: REST: create:POST, update=PUT, fetch=GET, delete=DELETE
//

const countryCode = process.env.NEXT_PUBLIC_COUNTRY_CODE as string;

export const API_ROUTES = {
  RETAILER: {
    CRUD: `/api/${countryCode}/retailer/`,
    LIST: `/api/${countryCode}/retailer/list/`,
    LINKS: `/api/${countryCode}/retailer/links/`,
    INCOUNTRIES: `/api/${countryCode}/retailer/in-countries/`,
    IMPORTSETTINGS: `/api/${countryCode}/retailer/import-settings/`,
    SAVEIMPORTSETTINGS: `/api/${countryCode}/retailer/save-import-settings/`,
    FEEDFORMATS: `/api/${countryCode}/retailer/feed-formats/`,
    AUTODETECTFEEDSETTINGS: `/api/${countryCode}/retailer/auto-detect-feed-settings/`,
    FIELDSFORTAGGINGLANGUAGES: `/api/${countryCode}/retailer/fields-for-tagging-languages/`,
    USERSTORETAILERS: `/api/${countryCode}/retailer/retailer-to-users/`,
    OVERVIEW: `/api/${countryCode}/retailer/overview/`,
    DETAILS: `/api/${countryCode}/retailer/details/`,
    AFFILIATENETWORKS: `/api/${countryCode}/retailer/affiliate-networks/`,
    COUNTRIES: `/api/${countryCode}/retailer/countries/`,
    SAVERETAILERDETAILS: `/api/${countryCode}/retailer/save-retailer-details/`,
    CPCCLASSES: `/api/${countryCode}/retailer/cpc-classes/`,
    GETPRODUCTCOUNTBYRETAILER: `/api/${countryCode}/retailer/get-retailer-products-count/`,
    GETFILTEROPTIONSBYRETAILER: `/api/${countryCode}/retailer/get-retailer-filter-options/`,
    FEEDADVANCEDSETTINGSCHECKFINALIZEFILTERS: `/api/${countryCode}/retailer/check-retailer-advanced-filter-options/`,
    SAVEADVANCEDIMPORTSETTINGS: `/api/${countryCode}/retailer/save-advanced-import-settings/`,
    RETAILERSBYCOUNTRY: `/api/${countryCode}/retailer/retailers-by-country/`,
    PRODUCTSCOUNTOVERVIEW: `/api/${countryCode}/retailer/product-count-overview/`,
    GETPRODUCTSINFEEDFORRETAILER: `/api/${countryCode}/retailer/get-retailer-products-in-feed/`,
    GETPRODUCTSLIVEFORRETAILER: `/api/${countryCode}/retailer/get-retailer-products-live/`,
    COUPONS: `/api/${countryCode}/retailer/retailer-coupons/`,
    CPCRULES: `/api/${countryCode}/retailer/cpc-rules/`
  },
  USER: {
    LIST: `/api/${countryCode}/user/list/`, // REST: fetch=GET
    CRUD: `/api/${countryCode}/user/`,
    ADDITIONALACCOUNT: `/api/${countryCode}/user/additional-account/`
  },
  ROLE: {
    // LIST: `/api/${countryCode}/role/list/`, // REST: fetch=GET
    CRUD: `/api/${countryCode}/role/`
  },
  ALC: {
    // LIST: `/api/${countryCode}/access-control-list/list/`, // REST: fetch=GET
    CRUD: `/api/${countryCode}/access-control-list/`
  },
  AUTH: {
    LOGIN: `/api/${countryCode}/auth/login/`,
    LOGOUT: `/api/${countryCode}/auth/logout/`,
    ME: `/api/${countryCode}/auth/me/`
  },
  LOGS: {
    ENTITYCHANGELOG: `/api/${countryCode}/logs/entity-changelog/`
  },
  CSS: {
    RETAILER: `/api/${countryCode}/css/retailer/`
  }
};

// https://www.restapitutorial.com/httpstatuscodes.html
export enum HTTPStatus {
  OK = 200,
  NoContent = 204,
  BadRequest = 400,
  Unauthorized = 400,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  Conflict = 409,
  UnprocessableEntity = 422,
  InternalServerError = 500,
  Created = 201
}

// https://www.restapitutorial.com/lessons/httpmethods.html
export enum RESTMethod {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE'

  // PATCH = 'PATCH',
}
