import type { Country } from 'src/types/countryTypes';
import * as process from 'process';

export * from './common-messages';

export const COUNTRIES: Record<string, Country> = {
  it: {
    countryId: 'it',
    countryName: 'Italy'
  },
  gb: {
    countryId: 'gb',
    countryName: 'UK'
  },
  de: {
    countryId: 'de',
    countryName: 'Germany'
  }
};

export const AUTH_COOKIE_NAME = '_m33bos';

export const SINGLE_COUNTRY_AVAILABLE = 1;

export const PAYMENT_TYPES: { key: number; value: string }[] = [
  { key: 0, value: 'SEPA' },
  { key: 1, value: 'Network' },
  { key: 2, value: 'Invoice' }
];

export const PAYMENT_TYPE_NAMES = {
  SEPA: 'SEPA',
  NETWORK: 'Network',
  INVOICE: 'Invoice'
};

export const RETAILER_TYPE_NAMES = {
  CPO: 'cpo',
  CPC: 'cpc'
};

export const INDICES = {
  PRODUCTS: process.env.ES_PRODUCTS_INDEX,
  CATEGORIES: process.env.ES_CATEGORIES_INDEX,
  BRANDS: process.env.ES_BRANDS_INDEX,
  IMPORT_STATS: process.env.ES_IMPORT_STATS_INDEX
};

export const COUPON_PRICE_TYPES = {
  NONE: 'none',
  SALE: 'sale',
  FULL_PRICE: 'fullPrice'
};
