// Messages
export const PROCESSING_REQUEST_MESSAGE = 'Processing...Please wait...';
export const LOADING_MESSAGE = 'Loading...';
export const SOMETHING_WENT_WRONG_MESSAGE = 'Something went wrong';
export const EMAIL_CONSTRAINT_ERROR_MESSAGE = 'For external users only the role Partner is allowed';

// Labels
export const PROCESSING_LABEL = 'Processing...';
export const SUBMIT_LABEL = 'Submit';
export const CANCEL_LABEL = 'Cancel';
