import { useState, useEffect, ReactNode } from 'react';
import { useRouter } from 'next/router';

interface Props {
  children: ReactNode;
}

const WindowWrapper = ({ children }: Props) => {
  const [windowReadyFlag, setWindowReadyFlag] = useState(false);
  const router = useRouter();

  useEffect(
    () => {
      if (typeof window !== 'undefined') {
        setWindowReadyFlag(true);
      }

      // @TODO
      //
      // Once we have country specific login, check every route change
      // in order to see if user.countryCodes contains current router.locale value,
      // if not router.push('/404-route/').
      //
      // console.log(router.route, 'WindowWrapper');
      // console.log(auth.user, 'auth.user');
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.route /*, auth.user*/]
  );

  if (windowReadyFlag) {
    return <>{children}</>;
  } else {
    return null;
  }
};

export default WindowWrapper;
