import { AbilityBuilder, Ability } from '@casl/ability';
import { AclPermissions } from 'src/lib/acl';
import type { IACLPermissions } from 'src/types/apps/accessControlListTypes';
import type { IPermissions } from 'src/types/apps/roleTypes';

export type Subjects = string;
export type Actions = 'manage' | 'read' | 'write';

export type AppAbility = Ability<[Actions, Subjects]> | undefined;

export const AppAbility = Ability as any;
export type ACLObj = {
  action: Actions;
  subject: string;
};

export const ACL_DASHBOARD_SUBJECT = 'Dashboard';

const defineRulesFor = (userACL: IACLPermissions[]) => {
  const { can, cannot, rules } = new AbilityBuilder(AppAbility);

  // Note:
  // This rule allows any signed-in user access dashboard (home) page,
  // but the page could be partially guarded with ACL rules.
  // Any other page which is not guarded with an ACL rule could be
  // visible from here (link could exist on the page).
  can([AclPermissions.read], ACL_DASHBOARD_SUBJECT);

  userACL.forEach((item: IACLPermissions) => {
    const aclSubject: string = Object.keys(item)[0];
    const permissions: IPermissions = Object.values(item)[0];

    // const permissions: IPermissions = userACL[aclSubject];

    if (permissions.read) can([AclPermissions.read], aclSubject);
    else cannot([AclPermissions.read], aclSubject);

    if (permissions.write) can([AclPermissions.write], aclSubject);
    else cannot([AclPermissions.write], aclSubject);
  });

  return rules;
};

export const buildAbilityFor = (userACL: IACLPermissions[]): AppAbility => {
  return new AppAbility(defineRulesFor(userACL), {
    // https://casl.js.org/v5/en/guide/subject-type-detection
    // @ts-ignore
    detectSubjectType: object => object!.type
  });
};

export const defaultACLObj: ACLObj = {
  action: AclPermissions.read,
  subject: ACL_DASHBOARD_SUBJECT
};

export default defineRulesFor;
