import { ReactNode } from 'react';
import { deepmerge } from '@mui/utils';
import { Theme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { Settings } from 'src/@core/context/settingsContext';
import themeConfig from 'src/configs/themeConfig';
import Direction from 'src/layouts/components/Direction';
import overrides from './overrides';
import typography from './typography';
import themeOptions from './ThemeOptions';
import UserThemeOptions from 'src/layouts/UserThemeOptions';
import GlobalStyling from './globalStyles';

interface Props {
  settings: Settings;
  children: ReactNode;
}

const ThemeComponent = (props: Props) => {
  const { settings, children } = props;
  const coreThemeConfig = themeOptions(settings);
  let theme = createTheme(coreThemeConfig);

  const mergeComponentOverrides = (theme: Theme, settings: Settings) =>
    deepmerge({ ...overrides(theme, settings) }, UserThemeOptions()?.components);

  const mergeTypography = (theme: Theme) =>
    deepmerge(typography(theme), UserThemeOptions()?.typography);

  theme = createTheme(theme, {
    components: { ...mergeComponentOverrides(theme, settings) },
    typography: { ...mergeTypography(theme) }
  });

  if (themeConfig.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return (
    <ThemeProvider theme={theme}>
      <Direction direction={settings.direction}>
        <CssBaseline />
        <GlobalStyles styles={() => GlobalStyling(theme, settings) as any} />
        {children}
      </Direction>
    </ThemeProvider>
  );
};

export default ThemeComponent;
